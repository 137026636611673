<template lang="pug">
Container
	UiBreadcrumbs

	div(class='Employee')
		div(class='Photo')
			UiImage(:image='employeeState.photo', width='500px', lazy)
		div(class='Content')
			h1(class='Uppercase') {{ header }}
			div(class='Skills') {{ employeeState.skills.join(', ') }}
			UiProse(class='Text', :html='employeeState.description', awesome)
			UiButton(theme='filled', size='xl', rounded, @click='getEmployeeModal') Записаться на консультацию

Container
	UnitReviews

Container
	UnitCases

Container
	UnitConsultation(type='employees')

UiSubscribe
</template>

<script lang="ts" setup>
/**
 * Define props
 */
const { alias } = defineProps<{
	alias: string;
}>();
/**
 * Define hooks
 */
const { header } = useMeta();
const { employeeState, fetchEmployee } = useEmployee(alias);
const { modalState, modalShow } = useModal();

/**
 * Get employee modal
 */
const getEmployeeModal = () => {
	if (!employeeState.value.form) return;

	modalState.value = {
		header: employeeState.value.form.header,
		description: employeeState.value.form.description,
		source: employeeState.value.form.source,
		subject: employeeState.value.form.subject,
		form: employeeState.value.form.fields,
		target: employeeState.value.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchEmployee();
</script>

<style lang="less" scoped>
.Employee {
	padding: 2rem 0 2rem 0;
	.box(grid; 100%; auto; none; 2rem 5rem; auto; 1fr; stretch; center);
	& > .Photo {
		max-height: 35rem;
		.grid(1 2; 1 2);
		.grid-aspect(2; 2.6);
		.relative(1; hidden);
		.box(flex; 100%; auto; none; flex-start; center; center; nowrap row);
		.border-radius(@BorderRadiusMedium);
		&:before {
			content: '';
			.absolute(1; none; 20% 0 0 0);
			.box(block; 100%; auto; @ColorWhite);
			.border-radius(@BorderRadiusMedium);
		}
		& > img {
			object-fit: contain;
			.relative(2);
			.box(block; auto; 100%);
		}
	}
	& > .Content {
		.box(grid; 100%; auto; none; 2rem; auto 1fr auto; auto; stretch; start);
		& > h1 {
			.grid(1 span);
		}
		& > .Skills {
			.grid(1 span);
			.text(@ColorBase; 1.25em 1.25rem @medium; left; none none);
		}
		& > .Text {
			.grid(1 span);
			.box(block; 100%; auto; none);
			.text(@ColorBase; 1.6em 1rem @regular; left; none none);
		}
	}

	@media all and (min-width: @laptop-small) {
		padding: 4rem 0 2rem 0;
		.box(grid; 100%; auto; none; 2rem 5rem; auto; 1fr 1.5fr; stretch; center);
		& > .Content {
			& > .Skills {
				.text(@ColorBase; 1.25em 1.5rem @medium; left; none none);
			}
		}
	}
}
</style>
